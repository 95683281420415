<template>
  <pro-menu-layout>
    <div class="q-ma-md">
      <pro-deck
        :title="getRes('Form.Section.ResubmitPricingDocumentsOffer')"
        :cards="items"
        :statuses="statuses"
        v-slot="{ card, status }"
        filter-by-status
      >
        <pro-deck-card
          v-bind="card"
          :status="status ? status.label : ''"
          :statusColor="status ? status.color : ''"
        >
          <template v-slot:action>
            <div class="row items-center no-wrap">
              <div class="col-auto ellipsis">
                {{ formatDate(card.closingDate) }}
              </div>
              <q-btn
                no-caps
                class="col-2 q-ml-md"
                padding="xs md"
                outline
                color="primary"
                :label="getRes('System.Button.View')"
                :to="getViewRequestForBestFinalOffer(card.ptnId)"
                style="width: 65px"
              />

              <q-btn
                no-caps
                v-if="isAllowIssuePtn(card)"
                class="col q-ml-sm"
                padding="xs md"
                outline
                color="primary"
                :label="getRes('System.Button.Issue')"
                :to="getPTNFormRoute(card.ptnId)"
                style="width: 65px"
              />
              <q-btn
                no-caps
                v-else-if="isAllowClosePtn(card)"
                :label="getRes('System.Button.Close')"
                class="col q-ml-sm"
                padding="xs md"
                outline
                color="primary"
                :to="getClosePTNFormRoute(card.ptnId)"
                style="width: 65px"
              />
              <div v-else style="width: 65px" class="col q-ml-sm" />
            </div>
          </template>
        </pro-deck-card>
      </pro-deck>
    </div>
  </pro-menu-layout>
</template>

<script>
import ProMenuLayout from "@/components/PROSmart/Layout/ProMenuLayout";
import ProDeckCard from "@/components/PROSmart/Deck/ProDeckCard";
import ProDeck from "@/components/PROSmart/Deck/ProDeck";

export default {
  components: { ProMenuLayout, ProDeckCard, ProDeck },
  props: {
    documentStatus: Number,
  },
  data() {
    return {
      items: [],
      issuePtnWorkflowCode: "",
      closePtnWorkflowCode: "",
      statuses: [
        {
          key: "Created",
          label: this.getRes("Document.Status.Created"),
          color: "green",
        },
        {
          key: "Issued",
          label: this.getRes("Document.Status.Issued"),
          color: "orange",
        },
        {
          key: "Closed",
          label: this.getRes("Document.Status.Closed"),
          color: "red",
        },
        {
          key: "NoResponse",
          label: this.getRes("Document.Status.NoResponse"),
          color: "purple",
        },
        {
          key: "Open",
          label: this.getRes("Document.Status.Open"),
          color: "blue",
        },
      ],
      allowPtnStatus: [50, 80],
    };
  },

  methods: {
    formatDate(date) {
      return this.$proSmart.common.getFormattedDate(new Date(date + "+0800"));
    },

    getPTNFormRoute(ptnId) {
      return {
        name: "DocumentViewPTNForm",
        params: {
          mode: "Edit",
          code: "P_T_IssuePtn",
          ptnId,
        },
      };
    },

    getClosePTNFormRoute(ptnId) {
      return {
        name: "DocumentViewPTNForm",
        params: {
          mode: "Edit",
          code: "P_T_ClosePtn",
          ptnId,
        },
      };
    },

    getViewRequestForBestFinalOffer(ptnId) {
      return {
        name: "DocumentViewRequestForBestAndFinalOffer",
        params: {
          ptnId,
        },
      };
    },

    isAllowIssuePtn(ptn) {
      return (
        ptn.status === "Created" &&
        this.allowPtnStatus.includes(this.documentStatus)
      );
    },

    isAllowClosePtn(ptn) {
      return (
        ptn.status === "Issued" &&
        ptn.isAllowEdit &&
        this.allowPtnStatus.includes(this.documentStatus)
      );
    },
  },

  async created() {
    const { id } = this.$route.params;
    const res = await this.$proSmart.documentUi.getTenderPtn(this, id);
    this.items = res.reduce(
      (tenderers, { SelectedTenderer: title, ptnStatus: status, ...rest }) => {
        return [...tenderers, { title, status, ...rest }];
      },
      []
    );
    // const {
    //   workflowCode: issuePtnWorkflowCode,
    // } = await this.$proSmart.tender.getWorkflowCode(
    //   this,
    //   this.$route.params.id,
    //   "issue_ptn"
    // );
    // const {
    //   workflowCode: closePtnWorkflowCode,
    // } = await this.$proSmart.tender.getWorkflowCode(
    //   this,
    //   this.$route.params.id,
    //   "close_ptn"
    // );
    // this.issuePtnWorkflowCode = issuePtnWorkflowCode;
    // this.closePtnWorkflowCode = closePtnWorkflowCode;
  },
};
</script>
